import React from 'react';

import './Wiki.css';

export function Wiki() {
  return (
    <section className="vithugOP-wiki">
      <div className="">
        <h1>Cool</h1>
        
      </div>      
    </section>
  );
}
