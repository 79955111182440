import React from 'react';
import logo from '../media/logo.png'
import './Hero.css';

export function Hero() {
  return (
    <section className="vithugOP-hero">
        <div className="vithugOP-heroimage">
            <h1>Vithug</h1>      
        </div>
    </section>        
  );
}
