import React from 'react';
import logo from '../media/logo.png'
import './Header.css';

export function Header() {
  return (
    <header className="vithugOP-header">
      <div className="">
        <img className="vithugOP-logo" src={logo} alt="Infowulf" />
        
      </div>      
    </header>
  );
}
